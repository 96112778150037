export const hideLocalDelivery = (brandId) => {
    return brandId == 6
};

export const isStyleEncore = (brandId) => {
    return brandId == 6
};

export const disableShippingCost = (brandId) => {
    return brandId == 6
};