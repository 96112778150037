import { Box } from "@bigcommerce/big-design";
import { theme } from "@bigcommerce/big-design-theme";
import styled from "styled-components";

export const OuterBox = styled(Box)<{isNavShown: boolean}>`
  display: flex;
  flex-direction: column;
  height: ${props => props.isNavShown ? '100%' : 'auto'};
  position: ${props => props.isNavShown ? 'fixed' : 'sticky'};
  ${theme.breakpoints.desktop} {
    position: sticky;
  }
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const HeaderBox = styled(Box)`
  flex: 0 1 auto;
  width: 100%;
  margin: 0 auto;
  background-color: #34313f;
`;

export const ContentBox = styled(Box)`
  margin: 0 auto;
  max-width: 1440px;
  ${theme.breakpoints.tablet} {
    width: 90%;
  }
`;

export const NavigationBox = styled(Box)`
  width: 100%;
  background-color: #34313f;
`;