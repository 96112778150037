import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Logo, Navigation } from "..";
import { ContentBox, HeaderBox, NavigationBox, OuterBox } from "./styled";
import { useStoreBasicInfo } from "../../services/getStore";

export const Header = (): JSX.Element => {
    const [isNavShown, setIsNavShown] = useState(false);
    const location = useLocation();
    const { data: store } = useStoreBasicInfo();

    useEffect(() => {
        setIsNavShown(false);
    }, [location]);

    return (
        <OuterBox isNavShown={isNavShown}>
            <HeaderBox paddingVertical={{ mobile: "medium", desktop: "large" }}>
                <ContentBox>
                    <Logo
                        isNavShown={isNavShown}
                        setIsNavShown={setIsNavShown}
                        store={store}
                    />
                </ContentBox>
            </HeaderBox>
            <NavigationBox
                display={{
                    mobile: isNavShown ? "block" : "none",
                    desktop: "block",
                }}
            >
                <Navigation
                    isNavShown={isNavShown}
                    isEcommerceActive={store?.commerce}
                    brandId={store?.brandId}
                />
            </NavigationBox>
        </OuterBox>
    );
};
