export const ROUTES = {
  SPLASH: `/`,
  MY_PROFILE: `/my-profile`,
  USERS: `/users`,
  CREATE_USER: `/users/create`,
  USER: `/users/:userId`,
  PAGES: `/pages`,
  ASSETS: `/assets`,
  PRODUCTS: `/products`,
  PRODUCT_RULES: `/product-rules`,
  PROMOTIONS: `/promotions`,
  SUPPORT: `/support`,
  LOGOUT: `/logout`,
};
