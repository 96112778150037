
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

export const setupStore = () => {
    return configureStore({
      reducer: rootReducer
    });
  };

const store = setupStore();
export default store;