import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorMessageObject } from "../types/messages";

const setErrorMessage = (
    data: ErrorMessageObject
): Promise<ErrorMessageObject> => {
    return Promise.resolve({ ...data });
};

export const useErrorMessage = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: ErrorMessageObject) => setErrorMessage(data),
        mutationKey: ["errorMessage"],
        onSuccess: (data) => {
            queryClient.setQueryData(["errorMessage"], data);
        },
    });
};
