import { lazy, Suspense, useEffect, useState, useContext, } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from './constants';
import { Header, PageContainer } from './components';
import getApiHost from './services/api/getApiHost';
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Box } from '@bigcommerce/big-design';
import { theme } from "@bigcommerce/big-design-theme";
import Cookies from 'js-cookie';
import { AppContext } from './wrappers/AppContext';
import { useErrorMessage } from './services/setErrorMessage';
import { useQueryClient } from "@tanstack/react-query";
import useCookieWatcher from './hooks/useCookieWatcher';
import Error from './pages/Error/Error';
import { useScrollToTop } from './hooks/useScrollToTop';

export const StyledBox = styled(Box)`
  margin: 15px;
  ${ theme.breakpoints.desktop } {
    width: 80%;
    margin: 20px auto; /* Center align the content */
    max-width: 1440px;
  }
  ${ theme.breakpoints.tablet } {
    width: 90%;
    margin: 20px auto; /* Center align the content */
  }
`;

export const WinmarkPage = (): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const context = useContext(AppContext);
  const queryClient = useQueryClient();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const errorMessageQuery = useErrorMessage();
  const navigate = useNavigate();

  const clearData = () => {
    queryClient.clear();
    Object.keys(Cookies.get()).forEach(function(cookieName) {
      Cookies.remove(cookieName);
    });
  }

  useScrollToTop();

  useEffect(() => {
    const authenticated = searchParams.get('authenticated') || false;
    const logout = searchParams.get('logout') || false;
    const error = searchParams.get('error') || false;
    
    if(location.pathname === '/error'){
      return;
    } else if (error) {
     setIsAuthenticated(false);
     navigate("/error");
    } else if ((location.pathname === '/' && !authenticated) || logout) {
      logout && clearData();
      window.location.href = `${getApiHost()}/login`;
    } else if(sessionStorage.getItem("loggedout") === "true"){
      return;
    } else {
      const role = searchParams.get('role') || ""
      const token = searchParams.get('token') || ""
      const refresh_token = searchParams.get('refresh_token') || ""
      
      role && Cookies.set('role', role, { expires: 365 })
      refresh_token && Cookies.set('refresh_token', decodeURIComponent(refresh_token), { expires: 1 });
      
      if(token) {
        Cookies.set('token', decodeURIComponent(token), { expires: 8 / 24 });
        window.location.href = `${window.location.protocol}//${window.location.host}?authenticated=true`;
      }

      // On route change, reset our global error message query
      errorMessageQuery.mutate({ description: "", messages: [] });
      setIsAuthenticated(true);
    }
  }, [searchParams, location.pathname]);

  useCookieWatcher((interval) => {
    if(isAuthenticated && location.pathname !== `/logout`){
        clearInterval(interval);
        setIsAuthenticated(false);
        clearData();
        sessionStorage.setItem("loggedout","true");
    }
  }, undefined, isAuthenticated);


  return (
    <>
    {isAuthenticated ?
      <PageContainer>
        <Header />
        <Router />
      </PageContainer>: null}
    {location.pathname === '/error' || sessionStorage.getItem("loggedout") === "true" ? <Error isLogout={sessionStorage.getItem("loggedout") === "true"} isError={location.pathname === '/error' ? true: false}/> : null}
    </>
  );
};

const Splash = lazy(() => import('./pages/Splash/Splash'));
const Users = lazy(() => import('./pages/Users/Users'));
const UserForm = lazy(() => import('./pages/UserForm/UserForm'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));
const StoreProfile = lazy(() => import('./pages/StoreProfile/StoreProfile'));
const CMSPages = lazy(() => import('./pages/CMSPages/CMSPages'));
const Assets = lazy(() => import('./pages/Assets/Assets'));
const Logout = lazy(() => import('./pages/Logout/Logout'));
const Support = lazy(() => import('./pages/Support/Support'));
const Products = lazy(() => import('./pages/Products/Products'));
const ProductEdit = lazy(() => import('./pages/Products/ProductEdit'));
const ProductBulkEdit = lazy(() => import('./pages/Products/ProductBulkEdit'));
const ProductRules = lazy(() => import('./pages/ProductRules/ProductRules'));
const Promotions = lazy(() => import('./pages/Promotions/Promotions'));
const BulkUpload = lazy(() => import('./pages/BulkUpload/BulkUpload'));

const styleRoutes = (component: any) =>{
  return (<StyledBox>{component}</StyledBox>);
}

const Router = (): JSX.Element => {
  const { SPLASH, MY_PROFILE, USERS, USER, CREATE_USER, PAGES, ASSETS, PRODUCTS, PRODUCT_RULES, PROMOTIONS, SUPPORT, LOGOUT } = ROUTES;
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path={SPLASH} element={<Splash />} />
        <Route path={MY_PROFILE} element={styleRoutes(<StoreProfile />)} />
        <Route path={USERS} element={styleRoutes(<Users />)} />
        <Route path={CREATE_USER} element={styleRoutes(<UserForm />)} />
        <Route path={USER} element={styleRoutes(<UserForm />)} />
        <Route path={PAGES} element={styleRoutes(<CMSPages />)} />
        <Route path={ASSETS} element={styleRoutes(<Assets />)} />
        <Route path={PRODUCTS} element={styleRoutes(<Products/>)}></Route> 
        <Route path={"products/edit/:id"} element={styleRoutes(<ProductEdit/>)}/>
        <Route path={"products/bulk-edit"} element={styleRoutes(<ProductBulkEdit/>)}/>
        <Route path={"products/bulk-upload"} element={styleRoutes(<BulkUpload/>)}/>
        <Route path={PRODUCT_RULES} element={styleRoutes(<ProductRules/>)}/>
        <Route path={PROMOTIONS} element={styleRoutes(<Promotions />)} />
        <Route path={SUPPORT} element={styleRoutes(<Support />)} />
        <Route path={LOGOUT} element={<Logout/>} />
        <Route path="/not-found" element={styleRoutes(<NotFound />)} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
  </Suspense>
  );
};
export default Router;