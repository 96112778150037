import { BrowserRouter } from 'react-router-dom';
import { WinmarkPage } from './Routes';
import { GlobalStore, AppContext } from './wrappers';
import { AlertsManager, GlobalStyles, createAlertsManager } from '@bigcommerce/big-design';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import "react-quill/dist/quill.snow.css";

const queryClient = new QueryClient({defaultOptions: {
  queries: {
    cacheTime: 1000 * 60 * 60 * 4, // 4 hour cache time
    staleTime: 1000 * 60 * 15, // 15 minute stale time
    retry: false,
    refetchOnWindowFocus: false,
  },
}});

export const alertsManager = createAlertsManager();

//@ts-ignore
function App() {
  return (
    //@ts-ignore
    <GlobalStore>
      {/* <EnvironmentConfigProvider environmentConfig={props}> */}
      <AppContext>
      <QueryClientProvider client={queryClient}>
      {/* {process.env.REACT_APP_NODE_ENV === 'development' && <ReactQueryDevtools />} */}
        <AlertsManager manager={alertsManager} />
        <BrowserRouter>
          <AlertsManager manager={alertsManager} />
          <GlobalStyles />
          <WinmarkPage />
        </BrowserRouter>
      </QueryClientProvider>
      </AppContext>
    {/* </EnvironmentConfigProvider> */}
    </GlobalStore>
  );
}

export default App;
