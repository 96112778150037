import Cookies from 'js-cookie';
import { useEffect, useRef } from 'react';

const cookiesToWatch = ["token", "refresh_token"];

const useCookieWatcher = (onChange: (cookie: string) => void, interval: number = 1000, isAuthenticated: boolean) => {
  const lastCookieRef = useRef<string>(document.cookie);

  useEffect(() => {
    if(isAuthenticated){
      const intervalId = setInterval(() => {
        const currentCookie = document.cookie;  
        if (currentCookie !== lastCookieRef.current) {
          let flag = true;
          cookiesToWatch.forEach((cookie)=>{
              if(Cookies.get(cookie)){
                  flag = false
              }
          });
   
          flag && onChange(currentCookie);
          lastCookieRef.current = currentCookie;
        }
      }, interval);

      return () => { 
        clearInterval(intervalId);
      };
    } 
  }, [interval, onChange]);
};

export default useCookieWatcher;