import { format, parseISO } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

const formatDateToLocal = (dateString: string, formatString: string = "EEE, dd MMM yyyy HH:mm:ss") => {
    if(!dateString){
        return "";
    }

    return format(
        utcToZonedTime(
            parseISO(dateString),
            Intl.DateTimeFormat().resolvedOptions().timeZone
        ),
        formatString
    );
}

export const formatDateToUTC = (dateString: string, formatString: string = "yyyy-MM-dd'T'HH:mm:ssxxx") => {
    if (!dateString) {
        return "";
    }

    return format(zonedTimeToUtc(
        parseISO(dateString),
        Intl.DateTimeFormat().resolvedOptions().timeZone
    ), formatString);
};

export default formatDateToLocal;
