import { PropsWithChildren } from 'react';
import { StyledBox } from './styled';

export const PageContainer = (props: PropsWithChildren): JSX.Element => {
  const { children, ...other } = props;

  return (
    <StyledBox
      {...other}
    >
      {children}
    </StyledBox>
  );
};