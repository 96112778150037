import React, { createContext, useContext } from 'react';

type EnvironmentConfig = {
    enableDevTools: boolean;
  // Add other configuration properties as needed
}

const defaultInitialValue: EnvironmentConfig = {
    enableDevTools: process.env.REACT_APP_NODE_ENV === 'development' ? true : false,
  };

const EnvironmentConfig = createContext<EnvironmentConfig>(defaultInitialValue);

// Custom hook to access the environment configuration
export const useEnvironmentConfig = () => {
  const config = useContext(EnvironmentConfig);
  if (!config) {
    throw new Error('useEnvironmentConfig must be used within an EnvironmentConfigProvider');
  }
  return config;
}

const EnvironmentConfigProvider = ({ environmentConfig, children }: {environmentConfig: EnvironmentConfig, children: React.ReactNode }) => {
  // Define your environment-related configuration here
//   const environmentConfig: EnvironmentConfig = {
//     isDevelopment: process.env.REACT_APP_NODE_ENV === 'development',
//     // Add other configuration properties as needed
//   };

  return (
    <EnvironmentConfig.Provider value={environmentConfig}>
      {children}
    </EnvironmentConfig.Provider>
  );
}

export default EnvironmentConfigProvider;
