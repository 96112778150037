const roleConfig =  {
   Admin: {
      logic_broker_access: true,
      pageAccess: {
        my_profile: true,
        users: true,
        pages: true,
        assets: true,
        products: true,
        product_rules: true,
        promotions: true,
        support: true,
        logout: true
      },
   },
   Manager: {
    logic_broker_access: true,
    pageAccess: {
        my_profile: true,
        users: false,
        pages: true,
        assets: true,
        products: true,
        product_rules: true,
        promotions: true,
        support: true,
        logout: true
    }
   },
   Marketing: {
    logic_broker_access: false,
    pageAccess: {
        my_profile: false,
        users: false,
        pages: true,
        assets: true,
        products: false,
        product_rules: false,
        promotions: false,
        support: true,
        logout: true
    }
   },
   Ecommerce: {
    logic_broker_access: true,
    pageAccess: {
        my_profile: false,
        users: false,
        pages: true,
        assets: true,
        products: true,
        product_rules: true,
        promotions: true,
        support: true,
        logout: true
    }
   }

}
export default roleConfig;