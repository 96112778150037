import { Button, Flex, H1 } from '@bigcommerce/big-design';
import { MenuIcon, LogoutIcon } from '@bigcommerce/big-design-icons';
import { WText } from '../WText';
import { StyledBox, StyledLink } from './styled';
import { TLogo } from './Logo.types';
import getApiHost from '../../services/api/getApiHost';

export const Logo = ({
  store,
  isNavShown,
  setIsNavShown,
  showNav = true
}: TLogo): JSX.Element => {

  return (
    <Flex
      alignItems='center'
      flexDirection='row'
      justifyContent={showNav ? "start": "space-between"}
    >
      {showNav && <StyledBox
        display={{ desktop: 'none' }}
      >
        <Button
          iconOnly={<MenuIcon color='white' />}
          marginRight={{ tablet: 'small' }}
          onClick={() => setIsNavShown?.(!isNavShown)}
          variant="subtle"
        />
      </StyledBox>}
      <Flex
        alignItems='center'
        flexDirection='row'
        flexWrap='wrap'
        marginLeft={{ mobile: "small", desktop: "medium" }}
      >
        <StyledLink
          href='/'
          marginRight='small'
        >
          <H1
            color='white'
            margin='none'
          >
            Winmark Sellers App
          </H1>
        </StyledLink>
        <WText
          bold={true}
          color='white'
        >
          {store?.brandTitle}
        </WText>
      </Flex>
      {!showNav && <StyledBox>
        <Button
          iconOnly={<LogoutIcon color='white' />}
          marginRight={{ tablet: 'small' }}
          onClick={() => window.location.href = `${getApiHost()}/logout`}
          variant="subtle"
        />
      </StyledBox>}
    </Flex>
  );
};