export const getMetaFieldProperties = (id, value) => {
    return {
            isLocal: true,
            value,
            key: id,
            namespace: "SellerApp",
            permission_set: "read_and_sf_access",
    }
}

export const getCustomFieldProperties = (id) => {
    return {
    }
}