export const checkImageLoad = async (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            resolve(true);
        };

        img.onerror = () => {
            resolve(false);
        };

        img.src = url;
    });
}