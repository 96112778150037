import { useQuery } from "@tanstack/react-query";
import Api from "./api";
import { Store } from "../types";

export const getStoreBasicInfo = (): Promise<Store> => {
  return Api.get({ url: `/api/v1/store/basic/info` }).then((response) => response.data.data);
};

export const useStoreBasicInfo = () => {
  return useQuery({
    queryKey: ['store'],
    queryFn: () => getStoreBasicInfo(),
  });
};