import { useContext } from 'react';
import { H2 } from '@bigcommerce/big-design';
import { NavigationContentBox, NavigationOverlay, StyledFlex, StyledLink, StyledNavigation } from './styled';
import { NavLinkProps } from './Navigation.types';
import { ROUTES } from '../../constants';
import enUS from '../../locales/en-US';
import { AppContext } from "../../wrappers/AppContext"
import { isStyleEncore } from '../../utils';

const { MY_PROFILE, USERS, PAGES, ASSETS, PRODUCTS, PRODUCT_RULES, PROMOTIONS, SUPPORT, LOGOUT } = ROUTES;

export const Navigation = ({
  isEcommerceActive = false,
  isNavShown = false,
  brandId
}): JSX.Element => {
  const context = useContext(AppContext);
  const {
    my_profile,
    users,
    pages,
    assets,
    products,
    product_rules,
    promotions,
    support,
    logout,
  } = context?.data?.accessConfig?.pageAccess || {};

  const items: Array<NavLinkProps> = [
    { id: "my_profile", href: MY_PROFILE, disabled: !my_profile },
    { id: "users", href: USERS, disabled: !users },
    { id: "pages", href: PAGES, disabled: !pages },
    { id: "assets", href: ASSETS, disabled: !assets },
    { id: "products", href: PRODUCTS, disabled: !isEcommerceActive || !products },
    { id: "product_rules", href: PRODUCT_RULES, disabled: isStyleEncore(brandId) || !isEcommerceActive || !product_rules },
    { id: "promotions", href: PROMOTIONS, disabled: !isEcommerceActive || !promotions },
    { id: "support", href: SUPPORT, disabled: !support },
    { id: "logout", href: LOGOUT, disabled: !logout },
  ];

  return (
    <StyledNavigation>
      <NavigationContentBox
        paddingBottom={{ mobile: 'none', desktop: 'medium' }}
      >
        <StyledFlex
          alignItems='center'
          display={{ mobile: 'block', desktop: 'flex' }}
          flexDirection='row'
          flexWrap='wrap'
          justifyContent='space-between'
        >
          {items.map((item:NavLinkProps) => (
            <StyledLink
              href={item.href}
              item={item}
              key={item.id}
            >
              <H2
                color='white'
                marginHorizontal={{ mobile: 'xLarge', desktop: 'none' }}
                marginVertical='none'
              >
                {enUS.navigation_title[item.id as keyof typeof enUS.navigation_title]}
              </H2>
            </StyledLink>
          ))}
        </StyledFlex>
      </NavigationContentBox>
      <NavigationOverlay display={{ mobile: (isNavShown ? 'block' : 'none'), desktop: 'none' }} />
    </StyledNavigation>
  );
};