const enUS = {
    cancel: "Cancel",
    confirm: "Confirm",
    create_user: "Create User",
    delete_user: "Are you sure you want to delete user ",
    error_message: "Something went wrong. Error message:",
    manage_my_orders: "Manage My Orders",
    new_store_user: "New Store User",
    navigation_title: {
        my_profile: "My Profile",
        users: "Users",
        pages: "Pages",
        assets: "Assets",
        products: "Products",
        product_rules: "Product Rules",
        promotions: "Promotions",
        support: "Support",
        logout: "Logout",
    },
    store_status: {
        status_0: "None",
        status_1: "Coming Soon",
        status_2: "Stocking up",
        status_3: "Grand opening",
    },
    store_settings: {
        active: "Active",
        commerce: "eCommerce Active",
        goldStar: "Gold Star",
    },
    update_user: "Update User",
    user: {
        labels: {
            active: "Active",
            email: "Email",
            first_name: "First Name",
            last_name: "Last Name",
            name: "Name",
            password: "Password",
            role: "Role",
        },
        alerts: {
            deleteUser: "User deleted successfully",
            createUser: "User created successfully",
            updateUser: "User updated successfully",
        },
        errors: {
            invalidEmail: "Invalid email format.",
            emailRequired: "Email is required.",
            firstNameRequired: "First name is required.",
            lastNameRequired: "Last name is required.",
            passwordRequired: "Password is required.",
            roleRequired: "Role is required.",
        },
    },
    support: {
        headers: {
            type: "Type",
            description: "Description",
            timestamp: "Added On",
            title: "Title",
        },
        documentType: {
            1: "Reference",
            2: "Policy",
            3: "Legal",
            0: "Other",
        },
    },
    assets: {
        columnHeaders: {
            images: "Images",
            fileName: "File Name",
            fileSize: "File Size",
            dateUploaded: "Date Uploaded",
            trash: "",
        },
        placeholders: {
            search: "Search Assets",
        },
        labels: {
            title: "Assets",
            trash: "trash",
            upload: "Upload",
            noAssets: "No assets available",
            modalHeader: "Uploading Assets",
            modalDescription:
                "Click the Choose Files button below to select images to upload. (.jpg, .jpeg, .png)",
        },
        errors: {
            duplicateUpload:
                "All images were uploaded successfully with the exception of the following which were found to be duplicates. Please rename these files and try again.",
        },
        alerts: {
            successfulUpload: "All images were uploaded successfully",
        },
    },
    weeks: {
        dayId_0: "Sunday",
        dayId_1: "Monday",
        dayId_2: "Tuesday",
        dayId_3: "Wednesday",
        dayId_4: "Thursday",
        dayId_5: "Friday",
        dayId_6: "Saturday",
    },
    cmsPages: {
        labels: {
            unpublished: "unpublished",
            previewButton: "Preview",
            saveChangesButton: "Save Changes",
            contentTab: "Content",
            settingsTab: "Settings",
            seoTab: "SEO",
            seoTitle: "Meta Title",
            seoDescription: "Meta Description",
            summaryBoxTitle: "Summary",
            statusLabel: "Status",
            liveStatus: "Live",
            unpublishedStatus: "Unpublished",
            createdByLabel: "Created By",
            createdDateLabel: "Date Created",
            updatedByLabel: "Updated By",
            updatedDateLabel: "Updated Date",
            viewLivePageLabel: "View Live Page",
            showSummaryLabel: "Show summary",
            hideSummaryLabel: "Hide summary",
            deletePageLabel: "Delete Page",
            accordionBlockLabel: "Accordion",
            accordionTitleLabel: "Section {index} - Title",
            accordionTextLabel: "Section {index} - Content",
            contentAlignmentLabel: "Content Alignment",
            alignLeftLabel: "Left",
            alignCenterLabel: "Center",
            alignRightLabel: "Right",
            imageAlignmentLabel: "Image Alignment",
            videoAlignmentLabel: "Video Alignment",
            backgroundImageLabel: "Background Image",
            backgroundAltTextLabel: "Background Image Alt Text",
            yellowBackgroundLabel: "Yellow",
            purpleBackgroundLabel: "Purple",
            blackBackgroundLabel: "Black",
            greenBackgroundLabel: "Green",
            whiteBackgroundLabel: "White",
            grayBackgroundLabel: "Gray",
            greyBackgroundLabel: "Grey",
            orangeBackgroundLabel: "Orange",
            darkGreenBackgroundLabel: "Dark Green",
            lightGreenBackgroundLabel: "Light Green",
            beigeBackgroundLabel: "Beige",
            barsBackgroundLabel: "Bars",
            decksBackgroundLabel: "Decks",
            speakersBackgroundLabel: "Speakers",
            contentImageLabel: "Content Image",
            contentImageAltTextLabel: "Content Image Alt Text",
            eyebrowImageLabel: "Eyebrow Image",
            heartsLabel: "Hearts",
            heartLabel: "Heart",
            verticalLinesLabel: "Vertical lines",
            soundWaveDarkLabel: "Sound wave dark",
            soundWaveLightLabel: "Sound wave light",
            starsLabel: "Stars",
            starLabel: "Star",
            zigZagLabel: "Zig-zag",
            diamondLabel: "Diamond",
            linesLabel: "Lines",
            diagonalLabel: "Diagonal",
            barsLabel: "Bars",
            rectangleLabel: "Rectangle",
            dotsLabel: "Dots",
            brushLabel: "Brush",
            noneLabel: "None",
            accentImageLabel: "Accent Image",
            accentPaperLabel: "Paper",
            accentPurpleLabel: "Purple",
            accentTealLabel: "Teal",
            accentYellowLabel: "Yellow",
            accentPinkLabel: "Pink",
            accentBlueLabel: "Blue",
            accentGrayLabel: "Gray",
            accentNavyLabel: "Navy",
            accentGreenLabel: "Green",
            accentOrangeLabel: "Orange",
            accentMarbleLabel: "Marble",
            accentSilkLabel: "Silk",
            accentYarnLabel: "Yarn",
            accentDenimLabel: "Denim",
            titleLabel: "Title",
            title1Label: "Title 1",
            title1BoldLabel: "Title 1 (Bold)",
            title2Label: "Title 2",
            title2BoldLabel: "Title 2 (Bold)",
            bodyCopyLabel: "Body Copy",
            primaryCTATextLabel: "Primary CTA Text",
            primaryCTALinkLabel: "Primary CTA Link",
            secondaryCTATextLabel: "Secondary CTA Text",
            secondaryCTALinkLabel: "Secondary CTA Link",
            toutImageLabel: "Tout {index} - Image",
            toutImageAltLabel: "Tout {index} - Image Alt Text",
            toutSubheadLabel: "Tout {index} - Subhead",
            toutBodyCopyLabel: "Tout {index} - Body Copy",
            toutLinkLabel: "Tout {index} - Link",
            slideImageLabel: "Slide {index} - Image",
            slideImageAltLabel: "Slide {index} - Image Alt Text",
            slideSubheadLabel: "Slide {index} - Subhead",
            slideBodyCopyLabel: "Slide {index} - Body Copy",
            slideLinkLabel: "Slide {index} - Link",
            videoLabel: "Video URL (YouTube or Vimeo)",
            jobListingDesciption: "Description"
        },
        descriptions: {
            seoTitle: "The meta title is important for SEO",
            seoDescriptionLine1:
                "The SEO Meta Description is a brief summary of the website page's content.",
            seoDescriptionLine2:
                "This description may appear in search engine results.",
        },
        dialog: {
            warnUnsavedChanges:
                "Changes won't be saved. Would you like to continue without saving?",
        },
        alerts: {
            successfulCreate: "Page created successfully",
            successfulUpdate: "Page updated successfully",
        },
        errors: {
            accordionTitleError: "Please enter a title.",
            accordionTextError: "Please enter text content.",
            backgroundImageError: "Please select a background image.",
            backgroundImageAltError: "Please enter background image alt text.",
            contentImageError: "Please select a content image.",
            contentImageAltError: "Please enter content image alt text.",
            accentImageError: "Please select an accent.",
            alignmentError: "Please select an alignment.",
            eyebrowImageError: "Please select an eyebrow image.",
            primaryCTATextError: "Please enter primary CTA text.",
            primaryCTALinkError: "Please provide primary CTA link.",
            secondaryCTATextError: "Please enter secondary CTA text.",
            secondaryCTALinkError: "Please provide secondary CTA link.",
            invalidLinkFormatError:
                "Link cannot include spaces or any of the following: [ ] { } ~ ^ | \\ ,",
            imageError: "Please select an image.",
            imageAltError: "Please enter image alt text.",
            subheadError: "Please enter a subheading.",
            bodyCopyError: "Please provide body text.",
            minimumSlidesError: "Minimum 3 slides required.",
            minimumToutsError: "Minimum 1 touts required.",
            minimumJLPositionError: "Minimum 1 position is required.",
            minimumAccordionError: "Minimum 1 accordion is required.",
            videoError: "Please enter video url",
        },
    },
    promotions: {
        labels: {
            name: "Name",
            code: "Code",
            startDate: "Start Date",
            endDate: "End Date",
            eligibility: "Eligibility",
            discountType: "Discount Type",
            discountOff: "Discount Off",
            redemptionLimitDesc: "Default value of 0 represents unlimited uses",
            eligibilityVal: "of",
            amount: "amount",
            maxAmount: "Maximum coupon uses",
            maxAmountCustomer: "Maximum coupon uses per customer",
            status: "Status",
            active: "Active",
            createPromotion: "Create Promotion",
            updatePromotion: "Update Promotion",
            promotions: "Promotions",
            expired: "Expired",
            enabled: "Enabled",
            disabled: "Disabled",
            minProductQty: "Minimum product quantity",
            minProductVal: "Minimum product value",
            flat: "Flat",
            percentage: "Percentage",
            eachEligible: "Each eligible product",
            allEligible: "All eligible products",
            edit: "Edit",
            summary: "Summary",
        },
        errors: {
          nameRequired: "Name is required.",
          codeRequired: "Code is required.",
          greaterThanZero: "Must be greater than 0.",
          minOneCategory: "Please select at least one category.",
          nonNegativeMaxUses: "Max coupon uses cannot be negative.",
          nonNegativeMaxUsesPerCustomer: "Max coupon uses per customer cannot be negative.",
          invalidDate: "Invalid date format. ",
          invalidValue: "Invalid value. ",
          endDateAfterStartDate: "End date must be after start date.",

        },
        alerts: {
            successfulCreate: "Promotion created successfully",
            successfulUpdate: "Promotion updated successfully",
            successfulDelete: "Promotion deleted successfully",
            confirmDelete: "Are you sure you want to delete this promotion?",
        }
    },
    shared_errors: {
        500: "Internal Server Error",
        unknown: "An unknown error has occurred",
    },
    formExtras: {
        charactersRemaining: "characters remaining.",
        characters: "characters.",
    },
};

export default enUS;
