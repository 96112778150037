export const getAllPriceGroups = (hierarchy) => {
const result = [];
    
const findLeafNodes = (node) => {
    if (node.depth == 2) {
        const labelParts = node.label?.split(":");
        const id = labelParts.length > 1? labelParts[0]?.trim()  : "";
        if(id){
            result.push({
                value: id,
                content: node.label
            });
        }
    }

    if (node.children) {
        node.children.forEach(child => {
            findLeafNodes(child);
        });
    }
}

hierarchy?.forEach(node => findLeafNodes(node));
return result;
}