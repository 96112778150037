import { Text, TextProps } from "@bigcommerce/big-design";

export const WText = (props: TextProps): JSX.Element => {
  const { children, ...rest } = props;

  return (
    <Text {...rest}>
      {children}
    </Text>
  );
};