import styled from "styled-components";
import { WLink } from "../WLink";
import { Box, Flex } from "@bigcommerce/big-design";
import { NavLinkProps } from "./Navigation.types";
import { theme } from "@bigcommerce/big-design-theme";

export const StyledNavigation = styled(Box)`
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 1440px;
  ${ theme.breakpoints.desktop } {
    position: relative;
    width: 90%;
  }
`;

export const NavigationContentBox = styled(Box)`
  background-color: #34313f;
  height: 100%;
  position: relative;
  width: 260px;
  z-index: 1;

  ${theme.breakpoints.desktop} {
    position: static;
    width: 100%;
  }
`;

export const StyledFlex = styled(Flex)`
  margin: 0 auto;
  max-width: 1440px;
`;

export const StyledLink = styled(WLink)<{ item: NavLinkProps }>`
  color: white;
  display: block;
  opacity: ${props => props.item.disabled ? .5 : 1};
  padding: ${theme.spacing.xSmall} 0;
  pointer-events: ${props => props.item.disabled ? 'none' : 'auto'};
  text-decoration: ${props => window.location.pathname === props.item.href ? 'underline 2px' : 'none' };
  text-underline-offset: 8px;

  ${theme.breakpoints.desktop} {
    color: white;
    display: inline;
    padding: 0;
  }

  &:hover:not(:active),
  &:active {
    background-color: #7d7d7d;
    color: white;

    ${theme.breakpoints.desktop} {
      background-color: transparent;
      text-decoration: underline 2px;
    }
  }

  h2 {
    color: inherit;
    font-size: ${theme.typography.fontSize.large};

    ${theme.breakpoints.desktop} {
      font-size: ${theme.typography.fontSize.large};
      //font-weight: bold;
    }
  }
`;

export const NavigationOverlay = styled(Box)`
  background-color: black;
  bottom: 0;
  height: 100%;
  opacity: .3;
  top: 0;
  position: absolute;
  width: 100%;
`;