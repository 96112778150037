export const  splitAtFirstHyphen = (inputString) => {
    // Find the index of the first hyphen
    let hyphenIndex = inputString.indexOf('-');

    // If no hyphen is found, return the original string in an array
    if (hyphenIndex === -1) {
        return [null, inputString];
    }

    // Split the string at the first hyphen
    let part1 = inputString.slice(0, hyphenIndex);
    let part2 = inputString.slice(hyphenIndex + 1);

    return [part1, part2];
};
