const replacePlaceholder = (data: {[key: string]: any }, index: string) => {
    const updatedData = {} as {[key: string]: string | object };
  
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];
        if (typeof value === 'string') {
          updatedData[key] = value.replace(/{index}/g, index);
        } else if (typeof value === 'object' && value !== null) {
          updatedData[key] = replacePlaceholder(value, index);
        } else {
          updatedData[key] = value;
        }
      }
    }
  
    return updatedData;
  };

  export default replacePlaceholder;
