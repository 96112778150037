import styled from 'styled-components';
import { WLink } from "../WLink";
import { Box } from '@bigcommerce/big-design';

export const StyledLink = styled(WLink)`
  color: white;

  &:hover:not(:active),
  &:active,
  &:focus {
    color: white;
    text-decoration: none;
  }

  h1 {
    font-weight: bold;
  }
`;

export const StyledBox = styled(Box)`
  button {
    &:hover:not(:active),
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  }
`;