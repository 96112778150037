import { Box, Modal, ModalAction } from "@bigcommerce/big-design";
import enUS from "../../locales/en-US";
import { useState } from "react";
import { StyledModalWrapper } from "./styled";

type TWModal = {
    children?: any;
    handleApply?: Function;
    handleClose?: Function;
    header?: string;
    isParentControlled?: boolean;
    isDestructive?: boolean;
    isOpen?: boolean;
    disabled?: boolean;
    variant?: "dialog" | "modal";
    closeOnClickOutside?: boolean;
    closeOnEscKey?: boolean;
    apply?: boolean
    actionsList?: [];
    maxWidth?:string;
};

const WModal = ({
    disabled,
    children,
    isOpen,
    handleApply,
    handleClose,
    isParentControlled,
    isDestructive = false,
    header = "",
    variant = "modal",
    closeOnClickOutside = false,
    closeOnEscKey = false,
    apply=true,
    actionsList,
    maxWidth="720px"
}: TWModal): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(
        isParentControlled ? isOpen : false
    );

    const actions: ModalAction[] = actionsList || [{
        text: enUS.cancel,
        variant: "subtle",
        mobileWidth: "100%",
        onClick: () => {
            handleClose?.();
            !isParentControlled && setIsModalOpen(false);
        },
    }];
    
    apply && actions.push({
        actionType: isDestructive ? "destructive" : "normal",
        text: enUS.confirm,
        disabled,
        mobileWidth: "100%",
        onClick: () => {
            handleApply?.();
            !isParentControlled && setIsModalOpen(false);
        },
    });
    
    return (
        <Modal
            actions={actions}
            closeOnClickOutside={closeOnClickOutside}
            closeOnEscKey={closeOnEscKey}
            isOpen={isParentControlled ? isOpen : isModalOpen}
            onClose={() => {
                handleClose?.();
                setIsModalOpen(false)
            }}
            variant={variant}
            header={header}
        >
            <StyledModalWrapper maxWidth={maxWidth} marginTop={header ? "small": "xLarge"}>{children}</StyledModalWrapper>
        </Modal>
    );
};

export default WModal;
