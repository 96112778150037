import { Box, Text } from "@bigcommerce/big-design";
import { Logo }  from "../../components/Logo/Logo";
import { HeaderBox, ContentBox } from "../../components/Header/styled";
import { WModal } from "../../components/WModal";

const Error = ({isLogout, isError}): JSX.Element => {
    return (
        <Box>
        <HeaderBox paddingVertical={{ mobile: "medium", desktop: "large" }}>
        <ContentBox>
            <Logo
                isNavShown={false}
                showNav={false}
            />
        </ContentBox>
       </HeaderBox>
        {isLogout? <WModal
            closeOnClickOutside={false}
            closeOnEscKey={false}
            isOpen={isLogout}
            isParentControlled={true}
            actionsList={[]}
            apply={false}
        >
            <Text marginVertical={"xxxLarge"}>
            You've been logged out. Please close this tab.
            </Text>
        </WModal>: null}
        {isError? <Box
            backgroundColor="white"
            border="box"
            borderRadius="normal"
            margin={{ mobile: "medium", desktop: "xxxLarge" }}
            padding={{ mobile: "medium", desktop: 'xLarge' }}
        >
            We are having trouble logging in. Please log out of the application and try again.
        </Box>: null}
        </Box>
    );
  };
  
  export default Error;
  