import { useNavigate } from "react-router-dom";
import { Link, LinkProps } from "@bigcommerce/big-design";

export const WLink = (props: LinkProps): JSX.Element => {
    const { children, href, ...rest } = props;
    const navigate = useNavigate();

    return (
        <Link {...rest} role="link" tabIndex={0} onClick={() => navigate(href)}>
            {children}
        </Link>
    );
};
