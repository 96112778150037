type FormatArrayToObject = (data: [{
    [key: string]: any
}], key: string) => {
    [key: string]: any
}

const formatArrayToObject: FormatArrayToObject = (data, key) =>{
    if(!data || !data.length){
        return {};
    }

    return data?.reduce((acc: any, current: any)=>{
        const dataKey = current[key];
        acc[dataKey] = current;
        return acc;
      }, {})
}
export default formatArrayToObject;