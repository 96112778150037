import React, { createContext, useState, ReactNode } from 'react';
import config from '../config';
import Cookies from 'js-cookie';

type Data = { accessConfig: {
  logic_broker_access: boolean,
  pageAccess: {
    my_profile: boolean,
    users: boolean,
    pages: boolean,
    assets: boolean,
    products: boolean,
    product_rules: boolean,
    promotions: boolean,
    support: boolean,
    logout: boolean,
  },
}}
type SharedData = {
  data: Data;
  setData: (role: string) => void;
};

// Create the context
export const AppContext = createContext<SharedData | undefined>(undefined);

// Create a custom provider component
type AppProviderProps = {
  children: ReactNode;
};

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const userRole = Cookies.get('role') || "";
  //@ts-ignore
  const [role, setData] = useState<string>(userRole); // Initial shared data
 
  //@ts-ignore
  const configuration = { accessConfig: config[userRole || role] }
  return (
    <AppContext.Provider value={{ data: configuration, setData }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

